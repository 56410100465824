.maincontainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: hidden;
}

.contentholder {
  height: 100vh;
  padding-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.namehead {
  color: white;
  font-size: 5vw;
}

.mainpara {
  color: #c7c7d6;
  font-weight: 400;
  font-family: "Garnett-medium";
  font-size: 2vw;
  padding: 5vh 10vw;
  text-align: center;
}

.scratchcard {
  margin-top: 5vh;
}

.cardholder {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.codestyle {
  color: white;
  font-size: 1.8vw;
  background: #1c1c1c;
  border-radius: 5px;
  padding: 1vh;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: hidden;
    position: relative;
    overscroll-behavior: none;
  }

  .contentholder {
    padding-top: 14vh;
    position: fixed;
    top: 0;
    overflow-y: hidden;
  }

  .namehead {
    font-size: 8vw;
  }

  .mainpara {
    font-size: 4vw;
    line-height: 3vh;
    padding: 4vh 10vw;
  }

  .codestyle {
    font-size: 4vw;
  }
}
